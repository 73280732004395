import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../layouts";

import "../global/styles/page-styles/404.scss";
import { TrackedLink } from "../components";

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Partie | 404</title>
        <html lang="en" />
        <meta name="description" content="Partie 404" />
      </Helmet>
      <PageLayout className="error-page" isErrorPage>
        <div className="error-page__container">
          <div className="error-page__content">
            <h2 className="error-page__error-code">404</h2>
            <h2 className="error-page__error-text">Page not found</h2>
            <p className="error-page__error-msg">
              Something went wrong, but we working on it. Let's get you back to{" "}
              <TrackedLink type="error-page__home-link" to="/" children="Partie" />
            </p>
            <img
              src="https://cdn.partie.com/downloads/assets/images/dog.gif"
              alt="partie pug"
              className="error-page__pug"
            />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default NotFoundPage;
